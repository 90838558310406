/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const UsuarioSCH = (perPage)=> {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['nome','email']
   return filtro
}
